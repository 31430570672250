// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
// eslint-disable-next-line import/prefer-default-export
export const mixinDate = {
  data() {
    return {
      DEFAULT_RETURN_STRING: '-',
      FES_DATE_FORMAT: 'DD | MMM | YYYY',
      FES_DATE_DASH_FORMAT: 'YYYY-MMM-DD',
      FES_DATE_SQL_FORMAT: 'YYYY-MM-DD',
      FES_DATE_TIME_FORMAT: 'DD | MMM | YYYY |  HH:mm:ss',
      DASH_DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
      DASH_TIME_FORMAT: 'YYYY-MM-DD HH:mm',
      LOCAL_TIME_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS',
    }
  },
  methods: {
    format(date) {
      return moment(date).format(this.FES_DATE_FORMAT)
    },
    emptyData(data) {
      if (data) {
        return data
      }
      return this.DEFAULT_RETURN_STRING
    },
    formatLocalDateTime(date) {
      if (date) {
        return moment(date).format(this.LOCAL_TIME_FORMAT)
      }
      return null
    },
    formatDateTimeDash(date) {
      if (date) {
        return moment(date).format(this.DASH_TIME_FORMAT)
      }
      return null
    },
    formatDate(date) {
      if (date) {
        return moment(date).format(this.FES_DATE_FORMAT)
      }
      return this.DEFAULT_RETURN_STRING
    },
    formatSqlDate(date) {
      if (date) {
        return moment(date).format(this.FES_DATE_SQL_FORMAT)
      }
      return this.DEFAULT_RETURN_STRING
    },
    formatDateDash(date) {
      if (date) {
        return moment(date).format(this.FES_DATE_DASH_FORMAT)
      }
      return this.DEFAULT_RETURN_STRING
    },
    formatTime(time) {
      if (time) {
        return moment(time, 'HH:mm:ss').format('hh:mm A')
      }
      return this.DEFAULT_RETURN_STRING
    },
    formatDateTime(date) {
      if (date) {
        return moment(date).utc().format(this.FES_DATE_TIME_FORMAT)
      }
      return this.DEFAULT_RETURN_STRING
    },
  },
}
