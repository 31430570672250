// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import constants from './static.json'

// eslint-disable-next-line import/prefer-default-export
export const mixinList = {
  data() {
    return {
      DEFAULT_RETURN_STRING: '-',
    }
  },
  methods: {
    emptyData(data) {
      if (data) {
        return data
      }
      return this.DEFAULT_RETURN_STRING
    },
    ageValidations(date) {
      const age = new Date().getFullYear() - moment(date).year()
      return (age > 13 && age < 75)
    },
    componentToHex(c) {
      const hex = c.toString(16)
      return hex.length === 1 ? `0${hex}` : hex
    },
    rgbToHex(r, g, b) {
      return `#${this.componentToHex(r)}${this.componentToHex(g)}${this.componentToHex(b)}`
    },
    changeVariant(index) {
      const variantList = ['light-success', 'light-primary', 'light-warning', 'light-info', 'light-secondary', 'light-danger']
      const modIndex = index % variantList.length
      return variantList[modIndex]
    },
    changeButtonVariant(index) {
      const variantList = ['success', 'primary', 'warning', 'info', 'secondary', 'danger']
      const modIndex = index % variantList.length
      return variantList[modIndex]
    },
    strSubstring(text, count) {
      if (text) {
        return text.slice(0, count) + (text.length > count ? '...' : '')
      }
      return ''
    },
    truncatedHtml(htmlContent, count) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = htmlContent
      const textContent = tempDiv.textContent || tempDiv.innerText || ''
      return textContent.length > count ? `${textContent.substring(0, count)}...` : textContent
    },
    getEventColor(eventStartTime) {
      const now = new Date()
      // Resetting hours, minutes, seconds, and milliseconds for an accurate comparison
      now.setHours(0, 0, 0, 0)

      const pastEventColor = constants.CHART_COLORS[3]
      const upcomingEventColor = constants.CHART_COLORS[4]
      const todayEventColor = constants.CHART_COLORS[5]

      const startTime = new Date(eventStartTime)
      startTime.setHours(0, 0, 0, 0) // Consider only the date part for comparison

      // Check if the event is today
      const isToday = now.getTime() === startTime.getTime()
      // Check if the event is upcoming
      const isUpcoming = startTime.getTime() > now.getTime()

      if (isToday) {
        return todayEventColor // Return the color for today's events
      }
      return isUpcoming ? upcomingEventColor : pastEventColor // Return the color based on whether the event is upcoming or past
    },
    removeProperties(obj, propsToRemove) {
      const newObj = { ...obj }
      propsToRemove.forEach(prop => {
        delete newObj[prop]
      })
      return newObj
    },
    resolveSalesLeadStatus(name) {
      const leadStatus = constants.SALES_LEADS_STATUS.find(i => i.name === name)
      if (leadStatus) {
        return leadStatus.variant
      }
      return ''
    },
  },
}
