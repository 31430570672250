// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import { USER_ROLE } from '@/constants/static.json'
import { mixinDate } from '@/constants/mixinDate'

export const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

export const sortCompare = key => (a, b) => {
  const fieldA = a[key]
  const fieldB = b[key]

  let comparison = 0
  if (fieldA > fieldB) {
    comparison = 1
  } else if (fieldA < fieldB) {
    comparison = -1
  }
  return comparison
}

export const isUserAdminInProject = (projectUsers, userData) => {
  if (
    userData.userRoleId === USER_ROLE.ADMIN
|| userData.userRoleId === USER_ROLE.COMPANY_ADMIN
  ) {
    return true
  }

  return projectUsers.some(
    user => user.userId === userData.id && user.isProjectAdmin === true,
  )
}

export const getRandomInt = (min, max) => {
  if (min > max) {
    const temp = max
    /* eslint-disable no-param-reassign */
    max = min
    min = temp
    /* eslint-enable */
  }

  if (min <= 0) {
    return Math.floor(Math.random() * (max + Math.abs(min) + 1)) + min
  }
  return Math.floor(Math.random() * max) + min
}

export const randomDate = (start, end) => {
  const diff = end.getTime() - start.getTime()
  const newDiff = diff * Math.random()
  const date = new Date(start.getTime() + newDiff)
  return date
}

export const isContentDeletable = data => {
  let result = true
  const today = moment(new Date()).format('YYYY-MM-DD')
  const startDate = moment(data.start).format('YYYY-MM-DD')
  if (today >= startDate) {
    result = false
  }
  if (data.isArchive === -1) {
    result = false
  }
  return result
}

export const isTodayOrFutureDate = date => {
  const today = moment(new Date()).format('YYYY-MM-DD')
  const formattedEndDate = moment(date).format('YYYY-MM-DD')
  return today <= formattedEndDate
}

export const isTodayOrPastDate = date => {
  const today = moment(new Date()).format('YYYY-MM-DD')
  const formattedEndDate = moment(date).format('YYYY-MM-DD')
  return today >= formattedEndDate
}

export const isContentEditableOrDeletable = (content, userRoleId) => {
  const today = moment()

  if (!content) {
    return false
  }

  // Only ADMIN or COMPANY_ADMIN roles can edit/delete
  if (userRoleId === USER_ROLE.ADMIN || userRoleId === USER_ROLE.COMPANY_ADMIN) {
    if (!content.end) {
      return true
    }
    const endDate = moment(content.end)
    if (endDate.isBefore(today, 'day') || content.recordStatus === -1) {
      return false
    }

    return true
  }

  return false
}

export const countingObject = data => {
  let usersCount = 0; let groupCount = 0; const users = []
  const playGroup = data.filter(
    group => (group.teamCoach === false),
  )
  groupCount = playGroup.length
  playGroup.forEach(group => {
    group.teamUsers.forEach(user => {
      // eslint-disable-next-line no-plusplus
      usersCount++
      users.push(user.user)
    })
  })
  return { usersCount, groupCount, users }
}

export const mapToContentTypeIcon = id => {
  switch (id) {
    case 1:
      return {
        icon: 'FileTextIcon',
        variant: 'light-success',
      }
    case 2:
      return {
        icon: 'AwardIcon',
        variant: 'light-primary',

      }
    case 3:
      return {
        icon: 'HelpCircleIcon',
        variant: 'light-info',
      }
    default:
      return {
        icon: 'FileIcon',
      }
  }
}

export const mapTeamRoleToVariant = teamCoach => {
  if (teamCoach) {
    return {
      id: 1,
      name: 'Coach',
      description_1: 'Coaches',
      description_2: 'Coaching Team',
      variant: 'success',
      Icon: 'UserIcon',
    }
  }
  return {
    id: 2,
    name: 'Member',
    description_1: 'Members',
    description_2: 'Team',
    variant: 'warning',
    Icon: 'UserIcon',
  }
}

export const mapFrequencyToVariant = frequency => {
  switch (frequency) {
    case 'one-time':
      return 'light-info'
    case 'weekly':
      return 'light-success'
    case '4-weeks':
      return 'light-primary'
    case '12-weeks':
      return 'light-warning'
    case '26-weeks':
      return 'light-primary'
    case '52-weeks':
      return 'light-danger'
    default:
      return 'light-secondary'
  }
}

export const mapTagToVariant = tag => {
  switch (tag) {
    case 'B2B Sales':
      return {
        id: 1,
        name: 'B2B Sales',
        description: 'Focus on business-to-business sales',
        variant: 'light-primary',
        Icon: 'StarIcon',
        svg: 'toolbox.svg',
      }
    case 'Social Selling':
      return {
        id: 2,
        name: 'Social Selling',
        description: 'Using social media to drive sales',
        variant: 'light-success',
        Icon: 'LinkedinIcon',
        svg: 'parachute.svg',
      }
    case 'Leadership':
      return {
        id: 3,
        name: 'Leadership',
        description: 'Leadership and management',
        variant: 'light-warning',
        Icon: 'SunIcon',
        svg: 'speaker.svg',
      }
    case 'Retail':
      return {
        id: 4,
        name: 'Retail',
        description: 'Managing retail and store sales',
        variant: 'light-secondary',
        Icon: 'ShoppingCartIcon',
        svg: 'star.svg',
      }
    case 'Consulting':
      return {
        id: 5,
        name: 'Consulting',
        description: 'Providing consulting services',
        variant: 'light-dark',
        Icon: 'TrelloIcon',
        svg: 'book.svg',
      }
    case 'Sustainability':
      return {
        id: 6,
        name: 'Sustainability',
        description: 'Focusing on sustainable practices',
        variant: 'light-info',
        Icon: 'SunIcon',
        svg: 'rocket.svg',
      }
    default:
      return {
        id: 0,
        name: '',
        description: '',
        variant: 'light-danger',
        Icon: 'FileIcon',
        svg: 'rocket.svg',
      }
  }
}

export const mapStatusToVariant = status => {
  switch (status) {
    case 'draft':
      return 'light-info'
    case 'Open':
      return 'light-success'
    case 'Closed':
      return 'light-secondary'
    default:
      return 'light-danger'
  }
}

export const mapLanguageToVariant = language => {
  switch (language) {
    case 'sv':
      return 'light-primary'
    case 'en':
      return 'light-info'
    default:
      return 'light-secondary'
  }
}

export const resolveConstantStatusVariant = data => {
  const mixin = mixinDate.methods
  const today = mixin.formatDateDash(new Date())
  const startDate = mixin.formatDateDash(data.start)
  const endDate = mixin.formatDateDash(data.end)
  if (data.record_status === 0) return { variant: 'info', text: 'unpublished' }
  if (today < startDate) return { variant: 'warning', text: 'unstarted' }
  if (today >= startDate && today <= endDate) return { variant: 'success', text: 'ongoing' }
  if (today > endDate) return { variant: 'secondary', text: 'finish' }
  return { variant: 'primary', text: '' }
}

export const resolveUserStatusVariant = status => {
  switch (true) {
    case status === 0:
      return { id: 1, name: 'Pending', variant: 'warning' }
    case status > 0:
      return { id: 2, name: 'Active', variant: 'success' }
    case status < 0:
      return { id: 3, name: 'Inactive', variant: 'secondary' }
    default:
      return { id: 0, name: 'Error', variant: 'danger' }
  }
}

export const resolveUploadUserStatusVariant = status => {
  switch (true) {
    case status === 1:
      return { id: 1, name: 'Pending', variant: 'success' }
    case status === 2:
      return { id: 2, name: 'Completed', variant: 'warning' }
    case status === -5:
      return { id: 3, name: 'Pending', variant: 'info' }
    default:
      return { id: 0, name: 'Error', variant: 'danger' }
  }
}

export const mapUserRoleToVariant = role => {
  let result = {}
  switch (role) {
    case USER_ROLE.ADMIN:
      result = {
        id: 1, name: 'Admin', variant: 'primary', Icon: 'ServerIcon',
      }
      break
    case USER_ROLE.COMPANY_ADMIN:
      result = {
        id: 2, name: 'Company admin', variant: 'warning', Icon: 'LayoutIcon',
      }
      break
    case USER_ROLE.USER:
      result = {
        id: 3, name: 'User', variant: 'success', Icon: 'UserIcon',
      }
      break
    default:
      result = {
        id: 0, name: '', variant: '', Icon: '',
      }
      break
  }

  return result
}

export const resolveProjectRole = role => (role
  ? {
    id: 1, name: 'Project Admin', variant: 'success', Icon: 'UserIcon',
  }
  : {
    id: 2, name: 'Member', variant: 'warning', Icon: 'UserIcon',
  })

export const resolveBatchUrl = index => {
  const icons = {
    // eslint-disable-next-line global-require
    1: require('@/assets/images/icons/gold.png'),
    // eslint-disable-next-line global-require
    2: require('@/assets/images/icons/silver.png'),
    // eslint-disable-next-line global-require
    3: require('@/assets/images/icons/bronze.png'),
    // eslint-disable-next-line global-require
    4: require('@/assets/images/icons/place.png'),
  }

  // Default to gold if the index is out of bounds
  const iconPath = icons[index] || icons[1]

  // eslint-disable-next-line global-require, import/no-dynamic-require
  return iconPath
}

export const mapPackageToVariant = packageId => {
  switch (packageId) {
    case 1:
      return {
        id: 1,
        name: '14-day FREE trial',
        variant: 'primary',
      }
    case 2:
      return {
        id: 2,
        name: '3 Monthly Contract',
        variant: 'warning',
      }
    case 3:
      return {
        id: 3,
        name: '6 Monthly Contract',
        variant: 'info',
      }
    case 4:
      return {
        id: 4,
        name: 'Annual Contract',
        variant: 'success',
      }
    default:
      return {
        id: 0,
        name: '',
        variant: 'danger',
      }
  }
}

export const mapTasksAndSubtasksToQuestions = tasks => {
  if (!Array.isArray(tasks)) {
    throw new Error('Invalid input: tasks must be an array')
  }

  const taskQuestionList = tasks.map(task => {
    let subtasks = [...task.subtasks]
    const questionsList = []
    const headerList = subtasks.filter(subtask => subtask.subtaskTypeId === 5)
    subtasks = subtasks.filter(subtask => subtask.subtaskTypeId !== 5)

    headerList.forEach(headerSubtask => {
      const question = {
        questionID: headerSubtask.id,
        statement: headerSubtask.name,
        description: headerSubtask.description,
        prompt: headerSubtask.prompt,
        maximumValue: headerSubtask.maxValue,
        recordStatus: headerSubtask.sequence,
        isMandatory: headerSubtask.isMandatory,
      }

      // Get options for the current header
      question.options = subtasks.filter(
        item => item.tag && item.tag === headerSubtask.tag,
      )

      // Remove the matched options from the main list
      subtasks = subtasks.filter(
        item => !(item.tag && item.tag === headerSubtask.tag),
      )

      // Determine questionType
      if (headerSubtask.subtaskTypeId === 6) {
        question.questionType = 'rating'
      } else if (question.options && question.options.length > 0) {
        question.questionType = headerSubtask.maxValue === 0 || headerSubtask.maxValue === 1
          ? 'radio-selection'
          : 'multiple-selection'
      } else {
        question.questionType = 'only-header'
      }

      questionsList.push(question)
    })

    // Handle remaining subtasks
    subtasks.forEach(subTask => {
      const question = {
        questionID: subTask.id,
        statement: subTask.name,
        description: subTask.description,
        prompt: subTask.prompt,
        options: [subTask],
        // eslint-disable-next-line no-nested-ternary
        questionType: subTask.subtaskTypeId === 1
          ? 'single-option'
          : subTask.subtaskTypeId === 6
            ? 'rating'
            : 'text-input',
        recordStatus: subTask.sequence,
        isMandatory: subTask.isMandatory,
      }

      questionsList.push(question)
    })

    // Sort questions by recordStatus
    questionsList.sort((a, b) => a.recordStatus - b.recordStatus)

    return {
      taskID: task.id,
      taskName: task.name,
      taskSequence: task.sequence,
      taskDescription: task.description,
      questions: questionsList,
    }
  })

  return taskQuestionList
}

export const mapTeamUsers = teams => {
  const allTeamUsers = []

  teams.forEach(team => {
    team.teamUsers.forEach(teamUser => {
      allTeamUsers.push({
        teamId: teamUser.teamId,
        teamCoach: team.teamCoach,
        userId: teamUser.userId,
        teamName: team.name,
        firstName: teamUser.user.firstName,
        lastName: teamUser.user.lastName,
        email: teamUser.user.email,
      })
    })
  })

  return allTeamUsers
}

export const mapSubtasksToQuestions = data => {
  if (!Array.isArray(data)) {
    throw new Error('Invalid input: data must be an array')
  }

  let subtasks = [...data]
  const questionsList = []
  const headerList = subtasks.filter(subtask => subtask.subtaskTypeId === 5)
  subtasks = subtasks.filter(subtask => subtask.subtaskTypeId !== 5)

  headerList.forEach(headerSubtask => {
    const question = {
      questionID: headerSubtask.id,
      statement: headerSubtask.name,
      description: headerSubtask.description,
      prompt: headerSubtask.prompt,
      maximumValue: headerSubtask.maxValue,
      recordStatus: headerSubtask.sequence,
      isMandatory: headerSubtask.isMandatory,
    }

    // Get options for the current header
    question.options = subtasks.filter(
      item => item.tag && item.tag === headerSubtask.tag,
    )

    // Remove the matched options from the main list
    subtasks = subtasks.filter(
      item => !(item.tag && item.tag === headerSubtask.tag),
    )

    // Determine questionType
    if (question.options && question.options.length > 0) {
      question.questionType = headerSubtask.maxValue === 0 || headerSubtask.maxValue === 1
        ? 'radio-selection'
        : 'multiple-selection'
    } else {
      question.questionType = 'only-header'
    }

    questionsList.push(question)
  })

  // Handle remaining subtasks
  subtasks.forEach(subTask => {
    const question = {
      questionID: subTask.id,
      statement: subTask.name,
      description: subTask.description,
      prompt: subTask.prompt,
      options: [subTask],
      questionType: subTask.subtaskTypeId,
      recordStatus: subTask.sequence,
      isMandatory: subTask.isMandatory,
    }

    questionsList.push(question)
  })

  // Sort questionsList by recordStatus
  questionsList.sort((a, b) => a.recordStatus - b.recordStatus)

  // Sort questionsList by recordStatus
  questionsList.sort((a, b) => a.recordStatus - b.recordStatus)
  return questionsList
}

/**
 * Update breadcrumbs dynamically
 * @param {Object} context - Vue instance or component `this`
 * @param {Array} breadcrumbArray - Array of breadcrumb objects
 */
export const updateBreadcrumb = (context, breadcrumbArray) => {
  if (!Array.isArray(breadcrumbArray) || breadcrumbArray.length === 0) {
    console.warn('Breadcrumb array must be a non-empty array.')
    return
  }

  if (!context || !context.$root) {
    console.error('Invalid context provided for breadcrumb update.')
    return
  }

  context.$root.$emit('update-breadcrumb', breadcrumbArray)
}